<template>
  <div class="page">
    <div class="page-title">大数据研判</div>
    <div class="page-header">
      <div class="page-header-sel">
        <el-select
          v-model="headerSelVal"
          @change="headerSelValChange"
          :popper-append-to-body="false"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div class="page-header-btn-list">
        <div class="page-header-btn1 header-btn" @click="getWaitForJudgmentData">
          <div>
            <img src="@/assets/img/zhang/huoqushuju.png" alt="" />
            <span class="big-span">获取数据</span>
            <br />
            <span class="small-span">(待处理: {{ waitForJudgmentNum }}条)</span>
          </div>
        </div>
        <div class="page-header-btn2 header-btn" @click="submitHarmfulInformation">
          <div>
            <img src="@/assets/img/zhang/tijiaoshuju.png" alt="" />
            <span>提交数据</span>
          </div>
        </div>
      </div>
      <div class="page-header-btn-back" @click="signOutDialogVisible = true">
        <img src="@/assets/img/zhang/tuichuyanpan.png" alt="" />
        <span>退出研判</span>
      </div>
    </div>
    <el-card shadow="never" class="big-data-info" v-loading="waitForJudgmentDataLoading">
      <div class="big-data-info-box" v-for="(item, index) in bigDataInfoList" :key="index">
        <div
          class="info-box-header"
          :class="item.machineMessageTypes.length > 0 ? 'info-box-header-red' : ''"
        >
          <el-select
            class="box-header-sel"
            v-model="item.machineMessageTypes"
            placeholder="请选择有害信息类型"
            multiple
            collapse-tags
            filterable
            style="width: 15rem"
            :popper-append-to-body="false"
          >
            <el-option
              v-for="item in errorTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <div class="box-header-btn" @click="emptyAllHarmfulType(index)">
            正常信息
          </div>
          <div
            class="box-header-h3"
            :class="item.machineMessageTypes.length > 0 ? 'box-header-h3-white' : ''"
            @click="openDrawer(item)"
          >
            {{ item.title }}
          </div>
          <div class="box-header-copy" @click="$main.copyFun(item.copyText)">
            <img
              v-if="item.machineMessageTypes.length > 0"
              src="@/assets/img/zhang/yijianfuzhi1.png"
              alt=""
            />
            <img v-else src="@/assets/img/zhang/yijianfuzhi.png" alt="" />
            <span
              style="color: #ed731f"
              :class="item.machineMessageTypes.length > 0 ? 'info-box-copy-color' : ''"
            >
              一键复制
            </span>
          </div>
        </div>
        <div
          class="info-box-main"
          :class="item.machineMessageTypes.length > 0 ? 'info-box-main-pink' : ''"
        >
          <div class="box-main-p">
            {{ item.content }}
          </div>
          <div class="box-main-author">
            <div class="main-author-1 main-author-author">信息来源：{{ item.source }}</div>
            <div class="main-author-2 main-author-author">所属平台：{{ item.platformName }}</div>
            <div class="main-author-3 main-author-author">所在地区：{{ item.areaName }}</div>
            <div class="main-author-4 main-author-author">发布时间：{{ item.publishTime }}</div>
            <div class="main-author-5 main-author-author">
              关键字：
              <span style="color: #e60b1e">{{ item.matchKeyword }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="noDataDis" v-if="bigDataInfoList.length == 0">
        点击获取数据
      </div>
    </el-card>
    <!-- 详情抽屉 -->
    <el-drawer
      title="信息详情"
      :with-header="false"
      :visible.sync="isDrawer"
      direction="rtl"
      size="50%"
    >
      <div class="drawer-header">
        <div class="drawer-header-title">信息详情</div>
        <div class="drawer-header-copy" @click="$main.copyFun(detailedData.copyText)">
          <img src="@/assets/img/zhang/yijianfuzhi.png" alt="" />
          <span>一键复制</span>
        </div>
      </div>
      <div class="drawer-theme">
        <div class="drawer-theme-top">
          <div class="theme-top-left">
            <div class="theme-tag" v-if="detailedData.bodyName">{{ detailedData.bodyName }}</div>
            <div class="theme-tag" style="background: #f58030">
              {{ detailedData.platformName }}
            </div>
          </div>
          <div class="theme-top-right">
            <span class="top-right-tag" v-if="detailedData.warningFlag">已预警</span>
            <div class="top-right-h3" :class="detailedData.warningFlag ? 'textIndent' : ''">
              {{ detailedData.title }}
            </div>
          </div>
        </div>
        <div class="drawer-theme-bottom">
          <div>平台类型：{{ detailedData.platformName || "无" }}</div>
          <div>账号名称：{{ detailedData.accountName || "无" }}</div>
          <div>作者名称：{{ detailedData.author || "无" }}</div>
          <div>发布时间：{{ detailedData.publishTime || "无" }}</div>
        </div>
      </div>
      <div class="drawer-info">
        <div class="drawer-info-title">信息详情</div>
        <p>{{ detailedData.content }}</p>
      </div>
      <div class="drawer-table">
        <div class="drawer-table-title">操作记录</div>
        <el-table
          :data="drawerData"
          stripe
          style="width: 100%"
          :header-cell-style="{ 'text-align': 'center', background: '#F6F7FA' }"
          :cell-style="{ 'text-align': 'center' }"
          :show-header="false"
        >
        <el-table-column prop="createTime"></el-table-column>
          <el-table-column prop="userName"></el-table-column>
          <el-table-column prop="reword"></el-table-column>
        </el-table>
      </div>
    </el-drawer>
    <!-- 退出研判 -->
    <el-dialog
      title="退出研判"
      :visible.sync="signOutDialogVisible"
      width="600px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <h3 class="delete_h3">请确认退出研判！</h3>
      <div slot="footer" class="dialog-footer">
        <el-button class="cancal_z" @click="signOutDialogVisible = false">取 消</el-button>
        <el-button class="search_z" type="primary" @click="quitPendingYes">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Bigdata from "@/api/bigdata/index";
import Error from "@/api/error/index";
export default {
  data() {
    return {
      // 退出研判弹窗
      signOutDialogVisible: false,
      // 提交数据的批次id
      batchId: "",
      // 详情数据
      detailedData: {},
      // 平台类型
      platformType: [],
      // 主体类型
      bodyType: [],
      // 加载效果
      waitForJudgmentDataLoading: false,
      // 待研判数据数量
      waitForJudgmentNum: 0,
      ////////////////////////////////////////
      headerSelVal: 0, //有害信息val
      options: [
        { value: 0, label: "有害信息" },
        { value: 1, label: "错误表述" },
        { value: 2, label: "异常状态" },
      ],
      errorTypeOptions: [],
      bigDataSelVal: 0,
      bigDataInfoList: [],
      isDrawer: false,
      drawerData: [], // 操作记录
    };
  },
  created() {
    this.getPlatformList();
    this.getSubjectList();
    this.getErrorTypeList();
    this.getHarmfulInformationNum();
  },
  mounted() {},
  methods: {
    async drawerDataFn(item) {
       const Res = await Bigdata.info({
        dataType:1, // 1 有害信息 2 错误表述 3 异常状态
        dataId:item.id
       })
       if(Res.data.code == 200) {
          this.drawerData = Res.data.data
       }
    },
    // 切换数据列表
    headerSelValChange() {
      if (this.headerSelVal == 0) {
        this.$router.push("/bigdata/harmfulInformation");
      }
      if (this.headerSelVal == 1) {
        this.$router.push("/bigdata/misrepresentation");
      }
      if (this.headerSelVal == 2) {
        this.$router.push("/bigdata/exceptionFilter");
      }
    },
    // 退出研判
    quitPendingYes() {
      // this.getUserOnLine(1, this.selectGroup.teamId);
      setTimeout(() => {
        window.close();
      }, 500);
    },
    // 提交数据
    async submitHarmfulInformation() {
      if (this.bigDataInfoList.length == 0) {
        this.$message.warning("请先获取数据后提交研判！");
        return false;
      }
      this.waitForJudgmentDataLoading = true;
      let data = {
        batchId: this.batchId,
        teamId: 2,
        user: JSON.parse(localStorage.getItem("user")).userId,
        userName: JSON.parse(localStorage.getItem("user")).name,
        data: [],
      };
      this.bigDataInfoList.forEach((x) => {
        let obj = {
          dataId: "",
          messageType: [],
          warnOrgInfo: null,
        };
        obj.dataId = x.id;
        if (x.machineMessageTypes.length == 0) {
          obj.messageType = null;
        } else {
          obj.messageType = x.machineMessageTypes;
        }
        data.data.push(obj);
      });
      const res = await Bigdata.submitHarmfulInformation(data);
      if (res.data.code == 200) {
        this.bigDataInfoList = [];
        this.getHarmfulInformationNum();
        this.waitForJudgmentDataLoading = false;
        this.$message.success("提交成功！");
      }
    },
    // 清空所有有害信息类型
    emptyAllHarmfulType(index) {
      this.bigDataInfoList[index].machineMessageTypes = [];
    },
    // 获取有害信息数据列表
    async getHarmfulInformationList(data) {
      const res = await Bigdata.getHarmfulInformationList(data);
      if (res.data.code == 200) {
        res.data.data.forEach((x) => {
          if (x.machineMessageTypes) {
            x.machineMessageTypes = x.machineMessageTypes.split(",");
          } else {
            x.machineMessageTypes = [];
          }
          this.platformType.forEach((y) => {
            if (x.platformType == y.value) {
              x.platformName = y.label;
            }
          });
          this.bodyType.forEach((z) => {
            if (x.bodyType == z.value) {
              x.bodyName = z.label;
            }
          });
          // 一键复制
          x.copyText =
            "标题：" +
            x.title +
            "\n" +
            "内容：" +
            x.content +
            "\n" +
            "有害类型：" +
            x.machineMessageTypesName +
            "\n" +
            "地区：" +
            x.areaName +
            "\n" +
            "时间：" +
            x.publishTime +
            "\n" +
            "关键字：" +
            x.matchKeyword +
            "\n";
        });
        this.bigDataInfoList = res.data.data;
        this.waitForJudgmentDataLoading = false;
      } else {
        this.bigDataInfoList = [];
        this.waitForJudgmentDataLoading = false;
      }
    },
    // 获取有害信息数据列表ID
    async getHarmfulInformationListId() {
      this.waitForJudgmentDataLoading = true;
      const res = await Bigdata.getHarmfulInformationListId({
        teamId: 2,
        user: JSON.parse(localStorage.getItem("user")).userId,
        userName: JSON.parse(localStorage.getItem("user")).name,
      });
      if (res.data.code == 200) {
        this.batchId = res.data.data.batchId;
        this.getHarmfulInformationList(res.data.data.dataIds);
      } else {
        this.bigDataInfoList = [];
        this.waitForJudgmentDataLoading = false;
      }
    },
    // 获取有害信息数量
    async getHarmfulInformationNum() {
      const res = await Bigdata.getHarmfulInformationNum();
      if (res.data.code == 200) {
        this.waitForJudgmentNum = res.data.data;
      }
    },
    // 获取事件类型
    async getErrorTypeList() {
      const res = await Error.getErrorTypeList();
      if (res.data.code == 200) {
        this.errorTypeOptions = res.data.data;
      }
    },
    // 获取平台类型
    async getPlatformList() {
      const res = await Error.getPlatformList();
      if (res.data.code == 200) {
        this.platformType = res.data.data;
      }
    },
    // 获取主体类型
    async getSubjectList() {
      const res = await Error.getSubjectList();
      if (res.data.code == 200) {
        this.bodyType = res.data.data;
      }
    },
    // 获取待研判数据
    getWaitForJudgmentData() {
      if (this.waitForJudgmentNum == 0) {
        this.$message.error("当前无待处理数据！");
        return false;
      }
      this.getHarmfulInformationListId();
    },
    // 打开详情抽屉
    openDrawer(item) {
      this.detailedData = item;
      this.isDrawer = true;
      this.drawerDataFn(item) // 操作记录
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input__inner {
  border-radius: 0px !important;
}
::v-deep .el-select-dropdown__item.selected {
  color: #ed731f !important;
}
::v-deep .el-select__tags-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100px;
}
::v-deep .el-select .el-input__inner:focus {
  border-color: #ed731f;
}
::v-deep .el-loading-spinner .path {
  stroke: #ed731f;
}
::v-deep .el-tag {
  border-radius: 0px !important;
}
.delete_h3 {
  font-size: 16px;
  color: #333333;
  padding: 30px 0;
  text-align: center;
  font-weight: 600;
}
.noDataDis {
  text-align: center;
  font-size: 40px;
  height: 500px;
  line-height: 500px;
  color: #eeeeee;
}
.page {
  .page-title {
    width: 130px;
    height: 37px;
    font-size: 26px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #1a1a1a;
    line-height: 37px;
    margin-bottom: 24px;
  }
  .page-header {
    position: relative;
    display: flex;
    justify-content: start;
    width: 100%;
    height: 90px;
    background: #ffffff;
    border-radius: 4px;
    padding: 12px 24px;
    position: sticky;
    top: 80px;
    z-index: 999;
    border-bottom: 1px solid #eeeeee;
    .page-header-sel {
      width: 272px;
      border-right: 1px solid #e4e6ec;
      padding-top: 12px;
      padding-bottom: 2px;
      margin-right: 32px;
      ::v-deep {
        .el-input__inner {
          width: 240px;
          height: 36px;
        }

        .el-input__prefix,
        .el-input__suffix {
          height: 36px;
        }
        .el-input.is-focus .el-input__inner {
          caret-color: #f58030;
          border: 1px solid #f58030 !important;
          transition: all 0.3s;
        }
      }
    }
    .page-header-btn-list {
      display: flex;
      justify-content: start;
      padding-top: 8px;
      .header-btn {
        width: 136px;
        height: 54px;
        border-radius: 4px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        text-align: center;
        line-height: 22px;
        div {
          height: 54px;
          padding-top: 5px;
          cursor: pointer;
          img {
            margin-right: 6px;
          }
          .small-span {
            font-size: 12px;
            font-weight: 400;
            color: #ffffff;
            line-height: 17px;
          }
        }
      }
      .page-header-btn1 {
        background: linear-gradient(270deg, #f89d55 0%, #f4630b 100%);
        margin-right: 32px;
      }
      .page-header-btn2 {
        background: #fff1e8;
        border-radius: 4px;
        display: flex;
        color: #ed731f;
        line-height: 42px;
        text-align: center;
        margin-right: 50px;
        img {
          width: 16px;
          height: 18px;
          vertical-align: middle;
        }
        div {
          width: 100%;
        }
      }
      .page-header-btn3 {
        background: #cccccc;
        margin-right: 32px;
      }
      .page-header-btn4 {
        background: #cccccc;
        border-radius: 4px;
        display: flex;
        line-height: 42px;
        text-align: center;
        margin-right: 50px;
        img {
          width: 16px;
          height: 18px;
          vertical-align: middle;
        }
        div {
          width: 100%;
        }
      }
    }
    .page-header-btn-back {
      position: absolute;
      top: 20px;
      right: 24px;
      width: 132px;
      height: 54px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #cccccc;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 54px;
      text-align: center;
      cursor: pointer;
    }
  }
  .big-data-info {
    margin-top: 16px;
    width: 100%;
    min-height: 680px;
    .big-data-info-box {
      width: 100%;
      height: 186px;
      border: 1px solid #e4e6ec;
      margin-bottom: 16px;
    }
    .info-box-header {
      position: relative;
      display: flex;
      width: 100%;
      height: 60px;
      background: #f5f6fa;
      padding: 12px 24px;
      .box-header-sel {
        width: 210px;
        ::v-deep {
          .el-input__inner {
            height: 36px;
          }

          .el-input__prefix,
          .el-input__suffix {
            height: 36px;
          }
          .el-input.is-focus .el-input__inner {
            caret-color: #f58030;
            border: 1px solid #f58030 !important;
            transition: all 0.3s;
          }
        }
      }
      .box-header-btn {
        width: 88px;
        height: 36px;
        background: #f7fffc;
        border: 1px solid #02bc7c;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #02bc7c;
        line-height: 36px;
        text-align: center;
        margin-right: 24px;
        margin-left: 10px;
        cursor: pointer;
      }
      .box-header-h3 {
        width: 1100px;
        height: 36px;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 36px;
        cursor: pointer;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .box-header-h3-white {
        color: white !important;
      }
      .box-header-copy {
        position: absolute;
        right: 24px;
        line-height: 20px;
        cursor: pointer;
        img {
          vertical-align: middle;
          margin-right: 6px;
        }
        span {
          display: inline-block;
          vertical-align: middle;
        }
      }
      img {
        width: 14px;
        height: 14px;
        margin: 11px 16px 11px 12px;
      }
    }
    .info-box-main {
      width: 100%;
      height: 126px;
      padding: 24px;
      .box-main-p {
        width: 100%;
        height: 40px;
        line-height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        margin-bottom: 17px;
        overflow: hidden;
      }
      .box-main-author {
        display: flex;
        .main-author-author {
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 20px;
          margin-right: 40px;
        }
      }
    }
  }
}
.info-box-header-red {
  background: #ea3342 !important;
}
.info-box-main-pink {
  background: #fff4f5;
}
.info-box-copy-color {
  color: #ffffff !important;
}
.drawer-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 64px;
  background: #fafafa;
  padding: 20px 24px;
  .drawer-header-title {
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #333333;
  }
  .drawer-header-copy {
    cursor: pointer;
    img {
      vertical-align: middle;
      margin-right: 6px;
    }
    span {
      vertical-align: middle;
      color: #ed731f;
    }
  }
}
.drawer-theme {
  width: 100%;
  min-height: 220px;
  padding: 60px 50px 40px;
  .drawer-theme-top {
    display: flex;
    width: calc(100% - 37px);
    // height: 80px;
    .theme-top-left {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 64px;
      height: 80px;
      margin-right: 16px;
      .theme-tag {
        width: 64px;
        height: 24px;
        background: #ff4b1f;
        border-radius: 1px;
        line-height: 24px;
        text-align: center;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
      }
    }
    .theme-top-right {
      position: relative;
      display: flex;
      width: 783px;
      // height: 80px;
      .top-right-tag {
        position: absolute;
        top: 8px;
        left: 0;
        display: inline-block;
        width: 58px;
        height: 24px;
        background: #ffedef;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ea3342;
        line-height: 24px;
        text-align: center;
      }
      .top-right-h3 {
        display: inline-block;
        // height: 80px;
        font-size: 28px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 38px;
      }
      .textIndent {
        text-indent: 65px;
      }
    }
  }

  .drawer-theme-bottom {
    display: flex;
    margin-top: 20px;
    div {
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
      margin-right: 60px;
    }
  }
}
.drawer-info {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: 1px solid #e4e6ec;
  border-bottom: 1px solid #e4e6ec;
  padding: 40px 50px 16px;
  .drawer-info-title {
    margin-bottom: 16px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 26px;
  }
  p {
    text-indent: 2rem;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 26px;
    margin-bottom: 24px;
  }
}
.drawer-table {
  width: 100%;
  padding: 40px 50px 32px;
  .drawer-table-title {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 26px;
    margin-bottom: 20px;
  }
}
//表格斑马纹颜色
::v-deep .el-table--striped .el-table__body tr.el-table__row--striped td {
  background: #ffffff;
}
::v-deep.el-table tr {
  background: #f6f7fa;
}
</style>
